.lower {
  &__header {
    background-color: $color-main;
    padding: 80px 0 30px;
    text-align: center;
    @include media(pc-s) {
      padding: res(80px) 0 res(30px);
    }
    @include media(sp) {
      padding: 70px 0 20px;
    }
  }
  &__title {
    display: inline-block;
    color: $color-wh;
    font-family: $font-mon;
    font-weight: bold;
    font-size: 3.2rem;
    padding: 38px 50px 40px;
    letter-spacing: track(50);
    background-image: url(../images/common/lower_header.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include media(pc-s) {
      font-size: res(3.2rem);
      padding: res(38px) res(50px) res(40px);
    }

    @include media(sp) {
      padding: 24px 30px 32px;
      font-size: 2.7rem;
    }

    span {
      font-family: $font;
      font-size: 1.4rem;
      display: block;
      margin-top: 10px;
      @include media(pc-s) {
        font-size: res(1.4rem);
        margin-top: res(10px);
      }

      @include media(sp) {
        font-size: 1rem;
      }
    }
  }
}
