/*ここに変数を定義*/

//font-family
$font: "YuGothic",
"Yu Gothic",
"Hiragino Sans",
"メイリオ",
sans-serif;
$font-mon : 'Montserrat', sans-serif;

//font-size
$font-size: 10px;

// カラー
$color-bk: #120E19;
$color-wh: #fff;
$color-main: #BA030F;
$color-pink: #E75A78;
$color-gray: #F7F7F7;
$color-sub01: #B8AED5;
$color-sub02: #F8EBE9;
$color-text: #333;

// clearfix  ※floatを使用した親要素に
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

// aタグの範囲を全体へ ※親要素にpoition: reletive;
@mixin linkall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// position-center ※親要素にpoition: reletive;
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//.wrapperから出したい(width:100%にしたい)場合ネガティブマージンを使う。
@mixin re-m {
  margin: 0 calc(50% - 50vw);
}

//.wrapperから出すがpaddingは.wrapperに合わせたい場合(主に背景色だけ100%に使う事が多い)
@mixin re-p {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
}


// breakpoint
$breakpoints: (pc-s: "screen and (max-width: 1360px)",
  tb: "screen and (max-width: 1080px)",
  sp: "screen and (max-width: 670px)",
  se: "screen and (max-width: 374px)",
);

@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
