/* TOP
------------------------------------------------- */
#anker01 {
  margin-top: -90px;
  padding-top: 90px;
  @include media(pc-s) {
    margin-top: res(-90px);
    padding-top: res(90px);
  }
  @include media(sp) {
    margin-top: -62px;
    padding-top: 62px;
  }
}
.l-company {

  .inner-company {
    position: relative;
    max-width: 1100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include media(pc-s) {
      max-width: res(1100px);
    }
  }

  &__title {
    position: relative;
    margin-top: 30px;
    font-size: 3.2rem;
    font-weight: bold;
    z-index: 10;
    @include media(pc-s) {
      margin-top: res(30px);
      font-size: res(3.2rem);
    }
    @include media(tb) {
      display: inline-block;
      font-size: 3.2rem;
      line-height: 1.5;
      margin-top: 0;
    }

    &::after {
      position: absolute;
      content: "";
      height: 6px;
      width: 101%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      background-color: #F5C834;
      z-index: -1;
      @include media(pc-s) {
        height: res(6px);
      }
      @include media(tb) {
        width: 110%;
        height: 6px;
        bottom: 1.8em;
      }
    }
    &::before {
      @include media(tb) {
        position: absolute;
        content: "";
        width: 5em;
        height: 6px;
        bottom: 0.3em;
        left: 50%;
        transform: translate(-50%);
        background-color: #F5C834;
        z-index: -1;
      }
    }
  }

  p {
    padding-right: 10px;
    text-align: left;
    @include media(pc-s) {
      padding-right: res(10px);
    }
    @include media(tb) {
      text-align: center;
      padding-left: 0;
      font-size: 1.4rem;
    }
  }
  .swiper {
    &--textbox {
      background-color: #F0F0F0;
      width: 70%;
      @include media(pc-s) {

      }
      @include media(tb) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }

      &__inner {
        max-width: 400px;
        width: 100%;
        padding-top: 146px;
        padding-bottom: 90px;
        padding-left: 20px;
        text-align: center;
        @include media(pc-s) {
          max-width: res(400px);
          padding-top: res(125px);
          padding-bottom: res(70px);
          padding-left: res(20px);
        }
        @include media(tb) {
          max-width: none;
          width: 100%;
          padding-top: 140px;
          padding-bottom: 24px;
          padding-left: 0;
          margin-top: -80px;
        }
      }
    }

    &__img {
      position: absolute;
      right: 0;
      top: 60px;
      max-width: 700px;
      width: 100%;
      @include media(pc-s) {
        top: res(60px);
        max-width: res(680px);
      }
      @include media(tb) {
        max-width: 700px;
        width: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        right: auto;
        padding: 0 20px;
      }
    }
  }
  .swiper-container {
    @include media(tb) {
      margin-top: -24px;
    }
  }



}

.swiper-container {
  padding-bottom: 38px !important;
  @include media(pc-s) {
    padding-bottom: res(38px) !important;
  }
  @include media(sp) {
    padding-bottom: 26px !important;
  }
}
.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  width: 50px;
  left: auto;
  right: 20px !important;
  @include media(pc-s) {
    right: res(20px) !important;
  }
  @include media(sp) {

  }
}

.l-service {

  &__list {
    display: flex;
    margin-top: 55px;
    gap: 3%;
    @include media(pc-s) {
      margin-top: res(55px);
    }
    @include media(sp) {
      flex-direction: column;
    }
  }

  &__item {

    & + .l-service__item {
      @include media(sp) {
        margin-top: 42px;
      }
    }

    .card {
      &__title {
        margin-top: 24px;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: track(50);
        text-align: center;
        font-family: $font-mon;
        @include media(pc-s) {
          margin-top: res(24px);
          font-size: res(1.8rem);
        }
        @include media(sp) {
          font-size: 1.8rem;
          margin-top: 15px;
        }
      }

      &__text {
        margin-top: 20px;
        @include media(pc-s) {
          margin-top: res(20px);
        }
        @include media(sp) {
          margin-top: 12px;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.l-news {

  &__list {
    max-width: 930px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    @include media(pc-s) {
      max-width: res(930px);
      margin-top: res(10px);
    }
    @include media(tb) {
      padding: 0 30px;
    }
    @include media(sp) {
      padding: 0;
      margin-top: 10px;
    }
  }
  &__item {
    padding: 28px 0;
    font-size: 1.5rem;
    @include media(pc-s) {
      padding: res(28px) 0;
      font-size: res(1.5rem);
    }
    @include media(sp) {
      padding: 16px 0;

    }

    &:last-child {
      padding-bottom: 0;
      @include media(pc-s) {

      }
      @include media(sp) {

      }
    }


    .card {
      display: flex;
      align-items: center;
      transition: opacity .2s ease-in;
      @include media(pc-s) {

      }
      @include media(sp) {
        flex-direction: column;
        align-items: flex-start;
      }

      &:hover {
        opacity: .6;
      }

      &__time {
        font-size: 1.5rem;
        letter-spacing: track(100);
        font-family: $font-mon;
        @include media(pc-s) {
          font-size: res(1.5rem);
        }
        @include media(sp) {
          font-size: 1.5rem;
        }
      }
      .wmidi {
        display: flex;
        align-items: center;
        max-width: 220px;
        width: 100%;
      }
      &__category {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 1rem;
        padding: 2px 4px;
        border-radius: 5px;
        background-color: #fff;
        color: #BA030F;
        border: #BA030F 1px solid;

        &:empty {
          border: none !important;
        }
      }
      &__title {
        // margin-left: 60px;
        letter-spacing: track(50);
        font-weight: bold;
        font-size: 1.5rem;
        @include media(pc-s) {
          // margin-left: res(60px);
          font-size: res(1.5rem);
        }
        @include media(sp) {
          // margin-left: 0;
          margin-top: 6px;
          font-size: 1.4rem;
        }
      }
    }

    & + .l-news__item {
      border-top: 2px dotted #707070;
      @include media(pc-s) {

      }
      @include media(sp) {

      }
    }
  }

}

.l-recruit {


  .inner-recruit {
    position: relative;
    max-width: 1100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media(pc-s) {
      max-width: res(1100px);
      padding: 0 20px;
    }
    @include media(sp) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 0 15px;
    }
  }

  &__box {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: (445/1110)*100%;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
    text-align: center;
    background-color: rgba(247, 247, 247, 0.85);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    @include media(pc-s) {
      max-width: res(445px);
    }
    @include media(sp) {
      position: relative;
      padding: 20px 0 20px;
      max-width: none;
      width: 100%;
      background-color: #F7F7F7;
      text-align: center;
      left: 0;
      transform: translateX(0%);
    }

    p {
      @include media(sp) {
        font-size: 1.4rem;
      }
    }
  }

  &__img {
    width: (354/1110)*100%;
    @include media(sp) {
      width: 100%;

      img {
        max-width: none;
        width: 100%;
      }
    }
  }
}



/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px) {}
