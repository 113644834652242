.button {
  width: 200px;
  margin: 30px auto 0;
  @include media(pc-s) {
    width: res(200px);
    margin: res(30px) auto 0;
  }
  @include media(tb) {
    width: res(160px);
    margin: res(20px) auto 0;
  }
  @include media(sp) {
    width: 200px;
    margin: 22px auto 0;
  }
  a {
    display: block;
    padding: 20px 0;
    font-size: 1.4rem;
    font-weight: bold;
    @include media(pc-s) {
      padding: res(20px) 0;
      font-size: res(1.4rem);
    }
    @include media(tb) {
      padding: res(16px) 0;
      font-size: res(1.2rem);
    }
    @include media(sp) {
      padding: 20px 0;
      font-size: 1.4rem;

    }
  }
  .confirm {
    display: block;
    padding: 20px 0;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: #fff;
    @include media(pc-s) {
      padding: res(20px) 0;
      font-size: res(1.4rem);
    }
    @include media(tb) {
      padding: res(16px) 0;
      font-size: res(1.2rem);
    }
    @include media(sp) {
      padding: 20px 0;
      font-size: 1.4rem;

    }}
}



.btn-grad {background-image: linear-gradient(to right, #c21500 0%, #ffc500  51%, #c21500  100%)}
.btn-grad {
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 5em;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
