/* footer
------------------------------------------------ */
.dragonflies {
  text-align: center;
  margin-top: 90px;
  @include media(pc-s) {
    margin-top: res(90px);
  }
  @include media(sp) {
    margin-top: 45px;
  }

  img {
    max-width: 300px;
    @include media(pc-s) {
      max-width: res(300px);
    }
    @include media(sp) {
      max-width: 280px;
    }
    transition: all ease-in-out .2s;
    &:hover {
      opacity: .8;
    }
  }

  &.none {
    display: none;
  }
}
.footer {
  padding: 70px 0;
  background-color: $color-bk;

  @include media(pc-s) {
    padding: res(70px) 0;
  }
  @include media(sp) {
    padding: 60px 0 20px;
  }

  &.mt {
    margin-bottom: auto;
    margin-top: 50px;
  }

  &__contact {
    text-align: center;
    margin-top: 90px;
    padding: 80px 0;
    background-color: $color-gray;
    @include media(pc-s) {
      margin-top: res(90px);
      padding: res(80px) 0;
    }
    @include media(sp) {
      margin-top: 45px;
      padding: 60px 0;
    }

    &.none {
      display: none;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    @include media(sp) {
      padding-left: 18px;
      max-width: 300px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__item {
    font-size: 1.4rem;
    font-weight: bold;
    color: $color-wh;
    transition: opacity .2s ease-in;
    @include media(pc-s) {
      font-size: res(1.4rem);
    }
    @include media(sp) {
      font-size: 1.2rem;
    }
    &:not(:first-child) {
      margin-left: 44px;
      @include media(pc-s) {
        margin-left: res(44px);
      }
      @include media(sp) {
        margin-left: 24px;

      }
    }
    &:nth-child(3n) {
      @include media(sp) {
      width: 40%;
      }
    }
    &:nth-child(4n) {
      @include media(sp) {
      margin-left: 0;
      }
    }
    &:nth-child(n + 4) {
      @include media(sp) {
      margin-top: 20px;
      }
    }
    &:hover {
      opacity: .8;
    }
  }

  &__copy {
    margin-top: 24px;
    color: #A798B7;
    text-align: center;
    font-size: 1rem;
    @include media(pc-s) {
      margin-top: res(24px);

    }
    @include media(sp) {
      margin-top: 42px;
    }
  }

}
