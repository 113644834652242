.service {
  font-family: $font-mon;

  &__title {
    margin-top: 90px;
    font-size: 3.2rem;
    letter-spacing: track(50);
    font-weight: bold;
    line-height: 1.4375;
    text-align: center;
    @include media(pc-s) {
      margin-top: res(90px);
      font-size: res(3.2rem);
    }
    @include media(sp) {
      font-size: 2rem;
    }
  }

  &__list {
    @include media(sp) {
      margin-top: 40px;
    }
  }

  &__item {

    &:not(:first-child) {
      @include media(sp) {
        margin-top: 35px;
      }
    }

    .card {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 90px;
      @include media(pc-s) {
        gap: res(30px);
        margin-top: res(90px);
      }
      @include media(sp) {
        flex-direction: column;
        margin-top: 23px;
        gap: 0;
      }

      &.fdcr {
        flex-direction: row-reverse;
        @include media(sp) {
          flex-direction: column;
        }
      }

      &__movie {
        position: relative;
        max-width: 640px;
        width: (640/1100)*100%;
        @include media(pc-s) {
          max-width: res(640px);
        }
        @include media(sp) {
          max-width: none;
          width: 100%;
        }
        video {
          width: 100%;
          max-width: none;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          @include media(pc-s) {

          }
          @include media(sp) {

          }
        }
      }

      &__textbox {
        width: (440/1100)*100%;
        @include media(sp) {
          width: 100%;
        }
      }

      &__title {
        font-size: 2.6rem;
        font-weight: bold;
        letter-spacing: track(50);
        @include media(pc-s) {
          font-size: res(2.6rem);
        }
        @include media(sp) {
          text-align: center;
          margin-bottom: 15px;
          font-size: 1.8rem;
        }
      }

      &__text {
        margin-top: 50px;
        line-height: 1.8;
        letter-spacing: track(50);
        @include media(pc-s) {
          margin-top: res(50px);
        }
        @include media(sp) {
          margin-top: 30px;
        }

        &.mt30 {
          margin-top: 30px;
          @include media(pc-s) {
            margin-top: res(30px);
          }
          @include media(sp) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}


.web-shop {
  background-color: #F7F7F7;
  padding: 38px;
  margin-top: 90px;
  @include media(pc-s) {
    padding: res(38px);
    margin-top: res(90px);
  }
  @include media(sp) {
    margin-top: 50px;
    padding: 11px 12px;
  }

  &__title {
    font-size: 3.2rem;
    letter-spacing: track(50);
    font-weight: bold;
    text-align: center;
    @include media(pc-s) {
      font-size: res(3.2rem);
    }
    @include media(sp) {
      font-size: 1.8rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    @include media(pc-s) {
      margin-top: res(35px);
    }
    @include media(sp) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  &__item {
    width: (500/1050)*100%;
    @include media(pc-s) {

    }
    @include media(sp) {
      width: 100%;
      margin-top: 15px;
    }

    &:nth-child(even) {
      margin-left: (50/1050)*100%;
      @include media(sp) {
        margin-left: 0;
      }
    }
    &:nth-child(n+3) {
      margin-top: (50/1050)*100%;
      @include media(sp) {
        margin-top: 15px;
      }
    }

    .card {
      padding: 25px;
      background-color: #fff;
      @include media(pc-s) {
        padding: res(25px);
      }
      @include media(sp) {

      }

      &__link {
        transition: opacity .2s ease-in-out;
        &:hover {
          opacity: .8;
        }
      }

      &__title {
        font-weight: bold;
        font-size: 1.6rem;
        padding-bottom: 2px;
        color: #0B0080;
        border-bottom: 1px solid #0B0080;
        @include media(pc-s) {
          font-size: res(1.6rem);
        }
        @include media(sp) {
          font-size: 1.4rem;
        }
      }

      &__img {
        margin-top: 20px;
        @include media(pc-s) {
          margin-top: res(20px);
        }
        @include media(sp) {
          margin-top: 20px;
        }
        img {
          @include media(sp) {
            max-width: none;
            width: 100%;
          }
        }
      }
    }
  }

}
