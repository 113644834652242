.sidebar-spacer {
  width: 26%;
  padding: 42px 25px;
  background-color: $color-gray;
  margin-left: 5%;
  @include media(pc-s) {
    padding: res(42px) res(25px);
  }
  @include media(tb) {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-top: 45px;
    padding: 50px 25px 34px;
  }
  @include media(sp) {
    max-width: 335px;
    width: 100%;
  }
}
.sidebar {
  &__inner {
    padding: 28px 22px 46px;
    background-color: $color-wh;
    @include media(pc-s) {
      padding: res(28px) res(22px) res(46px);
    }
    @include media(sp) {
      padding: 28px 22px;
    }
  }

  &__title {
    padding-bottom: 20px;
    font-size: 1.5rem;
    letter-spacing: track(50);
    border-bottom: 1px solid #707070;
    font-weight: bold;
    @include media(pc-s) {
      padding-bottom: res(20px);
      font-size: res(1.5rem);
    }
    @include media(sp) {
      font-size: 1.5rem;
      padding-bottom: 20px;
    }
  }

  &__list {
    margin-top: 23px;
    @include media(pc-s) {
      margin-top: res(23px);
    }
    @include media(sp) {
      margin-top: 20px;
    }

    li {
      a {
        font-size: 1.5rem;
        letter-spacing: track(50);
        @include media(pc-s) {
          font-size: res(1.5rem);
        }
        @include media(sp) {
          font-size: 1.5rem;
        }
      }
      &:not(:first-child) {
        margin-top: 33px;
        @include media(pc-s) {
          margin-top: res(33px);
        }
        @include media(sp) {
          margin-top: 20px;
        }
      }
    }
  }
}


.sidebar-category {
  #sidebar {
    p {
      display: none;
    }
  }

  [role="navigation"] {
    > li {
      display: none;
      &.categories {
        display: block;
      }

      h2 {
        margin-top: 40px;
        padding-bottom: 20px;
        font-size: 1.5rem;
        letter-spacing: 0.05em;
        border-bottom: 1px solid #707070;
        font-weight: bold;
        @include media(pc-s) {
          padding-bottom: res(20px);
          font-size: res(1.5rem);
        }
        @include media(sp) {
          font-size: 1.5rem;
          padding-bottom: 20px;
        }
      }

      .cat-item {
        margin-top: 23px;
        @include media(pc-s) {
          margin-top: res(23px);
        }
        @include media(sp) {
          margin-top: 20px;
        }

        &.cat-item {
          margin-top: 33px;
        @include media(pc-s) {
          margin-top: res(33px);
        }
        @include media(sp) {
          margin-top: 20px;
        }
        }

        a {
          font-size: 1.5rem;
          letter-spacing: 0.05em;
          @include media(pc-s) {
            font-size: res(1.5rem);
          }
          @include media(sp) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .searchform {
    display: none;
  }
  .pagenav {
    display: none;
  }

}
