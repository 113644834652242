.news {

  .post {
    &__title {
      font-size: 3.2rem;
      font-family: $font-mon;
      line-height: 1.4375;
      @include media(pc-s) {
        font-size: res(3.2rem);
      }
      @include media(sp) {

      }
    }

    &__time {
      display: inline-block;
      margin-top: 18px;
      font-size: 1.3rem;
      font-family: $font-mon;
      @include media(pc-s) {
        margin-top: res(18px);
        font-size: res(1.3rem);
      }
      @include media(sp) {

      }
    }
    // &__tag {
    //   margin-top: 14px;
    //   color: #fff;
    //   a {
    //     margin-right: 0;
    //     font-size: 1.2rem;
    //     padding: 4px 12px;
    //     border-radius: 5px;
    //     background-color: #fff;
    //     color: #BA030F;
    //     border: #BA030F 1px solid;

    //     &:first-child {
    //       margin-left: -3em;
    //     }
    //   }
    // }
    &__tag {
      font-size: 1em;
      color: #fff;
    }
    .post-categories {
      display: flex;
      li {
        margin-top: 14px;
        a {
          margin-right: 4px;
          font-size: 1.2rem;
          padding: 4px 12px;
          border-radius: 5px;
          background-color: #fff;
          color: #BA030F;
          border: #BA030F 1px solid;
        }
      }
    }

    &__content {
      margin-top: 34px;
      @include media(pc-s) {
        margin-top: res(34px);
      }
      @include media(sp) {

      }
      h1 {
        position: relative;
        font-size: 2.4rem;
        font-weight: bold;
        padding-left: 20px;
        margin-top: 60px;
        margin-bottom: 20px;
        letter-spacing: track(18);
        @include media(pc-s) {
          font-size: res(2.4rem);
          padding-left: res(20px);
          margin-top: res(60px);
          margin-bottom: res(20px);
        }
        @include media(sp) {

        }

        &::before {
          position: absolute;
          content: "";
          background-color: $color-main;
          width: 6px;
          height: 1.5em;
          top: 50%;
          left: 0;
          border-radius: 5em;
          transform: translateY(-50%);
          @include media(pc-s) {
            width: res(6px);
          }
          @include media(sp) {
            width: 6px;
          }
        }
      }
      h2 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 14px;
        letter-spacing: track(18);
        @include media(pc-s) {
          font-size: res(1.8rem);
          margin-top: res(40px);
          margin-bottom: res(14px);
        }
        @include media(sp) {

        }
      }
      h3 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 12px;
        letter-spacing: track(18);
        @include media(pc-s) {
          font-size: res(1.6rem);
          margin-top: res(30px);
          margin-bottom: res(12px);
        }
        @include media(sp) {

        }
      }

      p {
        font-size: 1.5rem;
        @include media(pc-s) {
          font-size: res(1.5rem);
        }
        @include media(sp) {

        }

        &+ p {
          margin-top: 20px;
          @include media(pc-s) {
            margin-top: res(20px);
          }
          @include media(sp) {

          }
        }
      }
    }
  }

  &__item {
    padding-bottom: 24px;
    @include media(pc-s) {
      padding-bottom: res(24px);
    }
    @include media(sp) {

    }
    &:not(:first-child) {
      padding-top: 42px;
      border-top: 2px dotted #707070;
      @include media(pc-s) {
        padding-top: res(42px);
      }
      @include media(sp) {

      }
    }
    &:last-child {
      padding-bottom: 0;
      @include media(pc-s) {

      }
      @include media(sp) {

      }
    }

    .card {
      transition: opacity .2s ease-in;
      &:hover {
        opacity: .6;
      }
      &__titletext {
        display: flex;
        align-items: center;
        @include media(pc-s) {

        }
        @include media(sp) {
          flex-direction: column;
          align-items: start;
        }
      }

      &__time {
        font-family: $font-mon;
        font-size: 1.5rem;
        letter-spacing: track(100);
        @include media(pc-s) {
          font-size: res(1.5rem);
        }
        @include media(sp) {

        }
      }

      &__title {
        // margin-left: 60px;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: track(50);
        word-break: break-all;
        @include media(pc-s) {
          // margin-left: res(60px);
          font-size: res(1.5rem);
        }
        @include media(sp) {
          margin-top: 10px;
        }
      }
      &__text {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.8;
        letter-spacing: track(50);
        @include media(pc-s) {
          margin-top: res(20px);
          font-size: res(1.5rem);
        }
        @include media(sp) {

        }
      }
      .wmidi {
        display: flex;
        align-items: center;
        max-width: 220px;
        width: 100%;
      }
      &__category {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 1rem;
        padding: 2px 4px;
        border-radius: 5px;
        background-color: #fff;
        color: #BA030F;
        border: #BA030F 1px solid;

        &:empty {
          border: none !important;
        }
      }
      .post-categories {
        display: flex;
        li {
          margin-top: 14px;
          a {
            margin-right: 4px;
            font-size: 1.2rem;
            padding: 4px 12px;
            border-radius: 5px;
            background-color: #fff;
            color: #BA030F;
            border: #BA030F 1px solid;
          }
        }
      }
    }
  }

}

.news-box {
  max-width: 750px;
  width: 100%;
  margin: 90px  auto 0;
  @include media(pc-s) {
    max-width: res(750px);
    margin: res(90px)  auto 0;

  }
  @include media(sp) {
    margin: 40px  auto 0;
    padding: 0 20px;
  }

  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1100px;
    @include media(pc-s) {
      max-width: res(1100px);
    }
    @include media(tb) {
      flex-direction: column;
    }
  }

  .news {
    width: 72.5%;
    @include media(tb) {
      width: 100%;
    }

    &.post {
      width: 100%;
    }
  }
}

.prev-next {
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
    border-bottom: 1px solid $color-sub01;
    border-top: 1px solid $color-sub01;
    @include media(pc-s) {
      margin-top: res(70px);
    }
    @include media(sp) {

    }
    p {
      width: 50%;
      padding: 24px 34px;
      color: $color-wh;
      font-size: 1.4rem;
      line-height: 1.714285714;
      @include media(pc-s) {
        padding: res(24px) res(34px);
        font-size: res(1.4rem);
      }
      @include media(sp) {

      }

      &:first-child {
        position: relative;

        &.next {
          width: 100%;
          height: 72px;
          @include media(pc-s) {
            height: res(72px);
          }
          @include media(sp) {

          }

          a {
            display: inline;
            width: 50%;
            margin-right: auto;
            margin-left: 55%;
            @include media(pc-s) {

            }
            @include media(sp) {

            }
          }
          &::before {
            content: none;
          }
          &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 75%;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
            background-color: $color-sub01;
          }
        }

        &::before {
          position: absolute;
          content: "";
          width: 1px;
          height: 75%;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: $color-sub01;
        }
      }
    }
    a {
      color: $color-main;
    }
  }

  &__button {
    margin-top: 20px;
    text-align: center;
    @include media(pc-s) {
      margin-top: res(20px);
    }
    @include media(sp) {

    }
    a {
      font-size: 1.4rem;
      letter-spacing: track(80);
      color: $color-main;
      @include media(pc-s) {
        font-size: res(1.4rem);
      }
      @include media(sp) {

      }
    }
  }
}
