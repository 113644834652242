.company {

  &.sec {
    margin-top: 70px;
    @include media(pc-s) {
      margin-top: res(70px);
    }
    @include media(sp) {
      margin-top: 40px;
    }
  }
  &-inner {
    position: relative;
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    @include media(pc-s) {
      max-width: res(1290px);
    }
    @include media(sp) {
      padding: 0 20px;
    }
  }

  &-img {
    position: absolute;

    @include media(pc-s) {
    }

    @include media(sp) {
      display: none;
    }

    &.-img01 {
      width: (210/1290)*100%;
      left: (30/1290)*100%;
      top: 0;
    }
    &.-img02 {
      width: (210/1290)*100%;
      right: 0;
      top: 0;
    }
    &.-img03 {
      width: (160/1290)*100%;
      left: (110/1290)*100%;
      bottom: 0;
    }
    &.-img04 {
      width: (160/1290)*100%;
      right: (130/1290)*100%;
      bottom: 0;
    }
  }

  &-textbox {
    width: (666/1290)*100%;
    margin: 0 auto;
    text-align: center;
    @include media(sp) {
      width: 100%;

      p {
        text-align: left;
      }
    }
  }

  &__title {
    font-size: 4rem;
    margin-top: 50px;
    font-weight: bold;
    position: relative;
    z-index: 10;
    @include media(pc-s) {
      font-size: res(4rem);
      margin-top: res(50px);
      text-align: center;
      line-height: 1.5;
    }
    @include media(sp) {
      margin-top: 45px;
      font-size: 3.2rem;
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }

    &::after {
      position: absolute;
      content: "";
      height: 12px;
      width: 101%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      background-color: #F5C834;
      z-index: -1;
      @include media(pc-s) {
        height: res(12px);
      }
      @include media(sp) {
        width: 110%;
        height: 6px;
        bottom: 1.8em;
      }
    }

&::before {
  @include media(sp) {
    position: absolute;
    content: "";
    width: 5em;
    height: 6px;
    bottom: 0.3em;
    left: 50%;
    transform: translate(-50%);
    background-color: #F5C834;
    z-index: -1;
  }
}
  }


  &-info{
    margin-top: 130px;
    @include media(pc-s) {
      margin-top: res(130px);
    }
    @include media(sp) {
      margin-top: 60px;
    }

    .title {
      margin-left: (40/1100)*100%;
      @include media(tb) {
        margin-left: (150/1100)*100%;
      }
      @include media(sp) {
        margin-left: auto;
      }
    }
    &-box {
      position: relative;
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      @include media(pc-s) {
        max-width: res(1100px);
      }
      @include media(sp) {

      }
    }

    &-img {
      position: absolute;
      width: (445/1100)*100%;
      left: 0;
      top: -100px;
      @include media(pc-s) {
        top: res(-100px);
      }
      @include media(sp) {
        position: relative;
        top: 0;
        padding: 0 20px;
        margin-top: 40px;
        width: 100%;
        z-index: 20;
        img {
          max-width: none;
          width: 100%;
        }
      }
    }

    &-text {
      position: relative;
      width: (800/1100)*100%;
      margin-top: 84px;
      margin-left: auto;
      padding: 24px 22px;
      z-index: 10;
      background-color: #F7F7F7;
      @include media(pc-s) {
        margin-top: res(84px);
        padding: res(24px) res(22px);
      }
      @include media(sp) {
        padding-top: 75px;
        margin-top: -40px;
        width: 100%;
      }

      .text {

        &:first-child {
          margin-top: 0;
          @include media(pc-s) {
          }
        }
      }
    }
  }

  &-summary {
    margin-top: 110px;
    @include media(pc-s) {
      margin-top: res(110px);
    }
    @include media(sp) {
      margin-top: 70px;
    }

    &-table {
      margin-top: 30px;
      @include media(pc-s) {
        margin-top: res(30px);
      }
      @include media(sp) {
        margin-top: 26px;
      }

      &__list {
        display: flex;
        padding: 30px 0;
        padding-left: 22px;
        @include media(pc-s) {
          padding: res(30px) 0;
          padding-left: res(22px);
        }
        @include media(sp) {
          flex-direction: column;
          padding: 20px 0;
        }

        &+ .company-summary-table__list {
          border-top: 1px solid #ddd;

          @include media(sp) {
            border-top: 1px solid #B8AED5;
          }
        }

        &.noflex {
          display: block;
        }
      }

      &__title {
        width: (200/1100)*100%;
        font-weight: bold;
        @include media(sp) {
          width: 100%;
        }
      }

      &__map {
        position:relative;
        width:100%;
        height:0;
        padding-top:35.4954954954955%;
        @include media(sp) {
          padding-top: 71.64179104477611%;
        }

        iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
      }

      &__link {
        color: #240C6A;
      }
    }
  }
}

.spnoinner {
  @include media(sp) {
    padding: 0 !important;
  }
}

