/* スライドショー
-------------------------------------------- */
.kv {
  position: relative;
  background-color: $color-main;
  width: 100%;
  height: 100vh;
  text-align: center;

  &__img{
    position: absolute;
    // max-width: 962px;
    max-width: 65vw;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  &__scroll {
    position: absolute;
    right: 24px;
    bottom: 0;
    color: $color-wh;
    z-index: 10;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    a {
      position: relative;
      padding-bottom: 90px;

      &::before {
        position: absolute;
        content: "";
        width: 1px;
        height: 80px;
        background-color: $color-wh;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        animation: sdl 1.5s cubic-bezier(1, 0, 0, 1) infinite;
      }
    }
  }
}

@keyframes sdl {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px) {

}
