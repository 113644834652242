.pagination {
  &__list {
    display: flex;
    justify-content: center;
    margin-left: 38%;
    margin-top: 90px;
    @include media(pc-s) {
      margin-top: res(90px);
    }
    @include media(tb) {
      margin-top: 45px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--link {
    background-image: linear-gradient(to right, #c21500 0%, #ffc500  51%, #c21500  100%);
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 50%;
    display: block;
    padding: 10px 15px;
    @include media(pc-s) {
      padding: res(10px) res(15px);
    }
    @include media(tb) {
      padding: 10px 15px;
    }
    &:hover {
      background-position: right center; /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
  }

  &__item {
    &.is-current {
        background-color: #F1EBF8;
        padding: 10px 15px;
        border-radius: 50%;
        @include media(pc-s) {
          padding: res(10px) res(15px);
        }
        @include media(tb) {
          padding: 10px 15px;
        }

        .pagination__text {
          color: #4F31A5;
        }
    }

    &+ .pagination__item {
      margin-left: 10px;
      @include media(pc-s) {
        margin-left: res(10px);
      }
      @include media(tb) {
        margin-left: 10px;
      }
    }

  }

  &__text {
    font-size: 2rem;
    font-weight: bold;
    color: $color-wh;
    @include media(pc-s) {
      font-size: res(2rem);
    }
    @include media(tb) {
      font-size: 2rem;
    }
  }
}







