.contact {

  &__text {
    margin-top: 40px;
    text-align: center;
    line-height: 1.230769231;
    @include media(pc-s) {
      margin-top: res(40px);
    }
    @include media(sp) {

    }
  }

  &-box {
    padding: 90px 90px 32px;
    margin-top: 30px;
    background-color: #F7F7F7;
    @include media(pc-s) {
      padding: res(90px) res(90px) res(32px);
      margin-top: res(30px);
    }
    @include media(sp) {
      padding: 30px;
      margin-top: 30px;
    }

    &__title {
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: bold;
      @include media(pc-s) {
        margin-top: res(30px);
        margin-bottom: res(10px);
      }
      @include media(sp) {

      }

      &:first-child {
        margin-top: 0;
      }
    }

    .mini {
      font-size: 1.2rem;
      margin-left: 10px;
      font-weight: normal;
      @include media(pc-s) {
        font-size: res(1.2rem);
        margin-left: res(10px);
      }
      @include media(sp) {
        font-size: 1.2rem;
      }
    }

    .red {
      font-weight: bold;
      color: #BA030F;
      font-size: 1.2rem;
      margin-left: 10px;
      @include media(pc-s) {
        font-size: res(1.2rem);
        margin-left: res(10px);
      }
      @include media(sp) {
        font-size: 1.2rem;
      }
    }

    .flex {
      display: flex;
    }

  }






  &-btn {
    margin-top: 50px;
    transition: opacity .2s ease-in-out;
    @include media(pc-s) {
      margin-top: res(50px);
    }
    @include media(sp) {
      margin-top: 50px;
    }

    &:hover {
      opacity: .8;
    }
  }


  .contactConfirm {


    &__private {
      margin-top: 50px;
      margin-bottom: 20px;
      padding: 40px 30px;
      height: 25em;
      background-color: #fff;
      border: 1px solid #707070;
      overflow-y: scroll;
      @include media(pc-s) {
        margin-top: res(50px);
        margin-bottom: res(20px);
        padding: res(40px) res(30px);
        height: res(25em);
      }
      @include media(sp) {
        padding: 15px;

      }

      h2 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
        @include media(pc-s) {
          font-size: res(1.8rem);

        }
        @include media(sp) {

        }
      }
      h3 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 10px;
        @include media(pc-s) {
          font-size: res(1.6rem);
          margin-top: res(30px);
        }
        @include media(sp) {

        }
      }
      h4 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        @include media(pc-s) {
          font-size: res(1.5rem);
          margin-top: res(20px);
          margin-bottom: res(10px);
        }
        @include media(sp) {

        }
      }
    }

    &__text {
      margin-top: 50px;
      font-weight: bold;
      text-align: center;
      font-size: 1.8rem;
      @include media(pc-s) {
        margin-top: res(50px);
        font-size: res(1.8rem);
      }
      @include media(sp) {

      }

      span {
        font-weight: normal;
        font-size: 1.6rem;
        @include media(pc-s) {
          font-size: res(1.6rem);
        }
        @include media(sp) {

        }
      }
    }




  }

  .mwform-checkbox-field label, .mwform-radio-field label {
    @include media(sp) {
    display: block;
    margin-top: 3px;
    }
  }

.mw_wp_form.mw_wp_form_confirm.mw_wp_form_preview {
  p {
    font-size: 1.6rem;
    @include media(pc-s) {
      font-size: res(1.6rem);
    }
    @include media(sp) {

    }
  }

  .button {
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    @include media(pc-s) {
      max-width: res(500px);
      gap: res(40px);
    }
    @include media(sp) {

    }
    .btn-grad {
      max-width: 200px;
      width: 100%;
      @include media(pc-s) {
        max-width: res(200px);
      }
      @include media(sp) {

      }
    }
    .confirm {
      max-width: 200px;
      width: 100%;
      @include media(pc-s) {
        max-width: res(200px);
      }
      @include media(sp) {

      }
    }
  }
}


.mw_wp_form.mw_wp_form_input {

  .send-btn.confirm {
    display: none;
  }
}


.mwform-radio-field-text {
  font-size: 1.4rem;
  @include media(pc-s) {
    font-size: res(1.4rem);
  }
  @include media(sp) {
    font-size: 1.4rem;
  }

}
.mwform-radio-field .horizontal-item label{
  display: flex;
  align-items: center;
}
.name,
.company,
.mail,
.tel {
  max-width: 300px;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #AAAAAA;
  @include media(pc-s) {
    max-width: res(300px);
    padding: res(8px);
  }
  @include media(sp) {

  }
}
.textarea {
  max-width: 600px;
  height: 225px;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #AAAAAA;
  @include media(pc-s) {
    max-width: res(600px);
    height: res(225px);
    padding: res(8px);
  }
  @include media(sp) {

  }
}

.mwform-checkbox-field.horizontal-item {
  margin-top: 4px;
  margin-left: 2px;
  @include media(pc-s) {

  }
  @include media(sp) {

  }
}
}



.thanks {
  margin-top: 80px;
  text-align: center;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include media(pc-s) {
    margin-top: res(80px);
  }
  @include media(sp) {

  }


  &__title {
    font-weight: bold;
    font-size: 2.3rem;
    margin-bottom: 20px;
    @include media(pc-s) {
      font-size: res(2.3rem);
      margin-bottom: res(20px);
    }
    @include media(sp) {

    }
  }
}
