.recruit {

  &__title {
    margin-top: 80px;
    font-size: 3.6rem;
    color: #BA030F;
    font-weight: bold;
    text-align: center;
    @include media(pc-s) {
      margin-top: res(80px);
      font-size: res(3.6rem);
    }
    @include media(sp) {
      margin-top: 45px;
      font-size: 3rem;
    }
  }

  &__text {
    margin-top: 30px;
    text-align: center;
    @include media(pc-s) {
      margin-top: res(30px);
    }
    @include media(sp) {
      margin-top: 30px;
      text-align: left;
    }
    & + .recruit__text {
      margin-top: 32px;
      @include media(pc-s) {
        margin-top: res(32px);
      }
      @include media(sp) {
        margin-top: 30px;
      }
    }
  }



  &__movie {
    position: relative;
    margin-top: 100px;
    width: 100%;
    @include media(pc-s) {
      margin-top: res(100px);
    }
    @include media(sp) {
      margin-top: 60px;
    }
    video {
      width: 100%;
      max-width: none;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      @include media(pc-s) {

      }
      @include media(sp) {

      }
    }
  }

}

.work {
  margin-top: 160px;
  text-align: center;
  @include media(pc-s) {
    margin-top: res(160px);
  }
  @include media(sp) {
    margin-top: 94px;
  }

  &-box {
    margin-top: 60px;
    padding: 60px 95px;
    text-align: left;
    border: 1px solid #B8AED5;
    border-radius: 10px;
    @include media(pc-s) {
      margin-top: res(60px);
      padding: res(60px) res(95px);
    }
    @include media(sp) {
      margin-top: 40px;
      padding: 50px 20px;
    }

    &__title {
      font-size: 2.4rem;
      font-weight: bold;
      text-align: center;
      @include media(pc-s) {
        font-size: res(2.4rem);
      }
      @include media(sp) {
        font-size: 1.8rem;
      }
    }

    &__list {
      margin-top: 33px;
      @include media(pc-s) {
        margin-top: res(33px);
      }
      @include media(sp) {
        margin-top: 20px;
      }
    }

    &__item {
      display: flex;
      padding: 44px 0;
      @include media(pc-s) {
        padding: res(44px) 0;
      }
      @include media(sp) {
        flex-direction: column;
        padding: 20px 0;
      }

      & + .work-box__item {
        border-top: 1px solid #DDDDDD;
      }

      .title {
        width: (200/920)*100%;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: left;
        color: #333;
        line-height: 1.8;
        @include media(pc-s) {
          font-size: res(1.5rem);
        }
        @include media(sp) {
          width: 100%;
          font-size: 1.4rem;
        }
      }

      .textbox {
        width: (720/920)*100%;
        @include media(sp) {
          width: 100%;
        }

        .text {
          font-size: 1.5rem;
          margin-top: 0;
          line-height: 1.8;
          padding-left:1em;
          text-indent:-1em;
          @include media(pc-s) {
            font-size: res(1.5rem);
          }
          @include media(sp) {
            font-size: 1.4rem;
            padding-left:0;
            text-indent:0;
          }
        }
      }
    }
  }

}
.recruit-picarea {
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: 80px auto;

  &__list {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1%;
  }
  &__item {
    width: 32.6667%;
    background: #f7f7f7;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.4);


    &:nth-child(n +4) {
      margin-top: 1%
    }
    .card {


      &:hover {
        opacity: 0.8;
        transition: all ease-in-out .2s;
      }

      &__img img {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
        max-width: none;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }

      &__title {
        padding: 10px 10px;
        color: #333;
        font-size: 1.4rem;
        text-align: left;
        @include media(sp) {
          font-size: 1.1rem;
          padding: 5px 8px;
        }
      }
    }
  }
}
