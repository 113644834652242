.sec {
  margin-top: 90px;

  @include media(pc-s) {
    margin-top: res(90px);
  }

  @include media(sp) {
    margin-top: 50px;

    &.mtl {
      margin-top: 70px;
    }
  }
}
