.breadcrumb {
  background-color: $color-gray;

  &__list {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 18px 0;
    @include media(pc-s) {
      max-width: res(1100px);
      padding: res(18px) 0;
    }
    @include media(tb) {
      padding: 16px 20px;
    }
    @include media(sp) {

    }
  }

  &__item {
    font-family: $font-mon;
    font-size: 1.2rem;
    letter-spacing: track(50);
    @include media(pc-s) {
      font-size: res(1.2rem);
    }
    @include media(sp) {
      font-size: 1.1rem;
    }

    a {
      color: $color-main;
    }

    & + .breadcrumb__item {
      margin-left: 24px;
      position: relative;
      @include media(pc-s) {
        margin-left: res(24px);
      }
      @include media(sp) {
        margin-left: 24px;
      }

      &::before {
        position: absolute;
        content: ">";
        left: -14px;
        top: 0;
        @include media(pc-s) {
          left: res(-14px);
        }
        @include media(sp) {
          left: -14px;
        }
      }
    }
  }
}
