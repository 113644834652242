.scroll {
  transform: translateY(100px);
  opacity: 0;
  @include media(sp) {
    transform: translateY(50px);
  }

  &.isPlay {
    transform: translateY(0);
    opacity: 1;
    transition: 1s;
    @include media(sp) {
      transition: 1.2s;
    }
  }
}

