/* メインの設定(全体)
------------------------------- */

// common
.anker {
  display: block;
  margin-top: -80px;
  padding-top: 80px;

  @include media(pc-s) {
    margin-top: res(-80px);
    padding-top: res(80px);
  }

  @include media(sp) {
    margin-top: res(-54px);
    padding-top: res(54px);
  }
}

// inner
.inner {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;

  @include media(pc-s) {
    max-width: res(1100px);
  }

  @include media(tb) {
    padding: 0 20px;
  }

  @include media(sp) {
    padding: 0 15px;
  }
}

// main
.main {
  display: block;
}

p {
  font-size: 1.5rem;
  line-height: 1.8;
  letter-spacing: track(50);
  color: $color-text;
  @include media(pc-s) {
    font-size: res(1.5rem);
  }
  @include media(sp) {
    font-size: 1.5rem;
  }
}

.text {
  margin-top: 34px;
  @include media(pc-s) {
    margin-top: res(34px);
  }
  @include media(sp) {
    margin-top: 20px;
  }
}

// br関連
.d-tb,
.d-sp,
.d-tbOnly {
  display: none !important;
}

@include media(pc-s) {

  .d-pc {
    display: block !important;
  }

  .d-tb {
    display: none !important;
  }

  .d-sp {
    display: none !important;
  }

}

@include media(tb) {
  .d-pctb {
    display: none !important;
  }
  .d-tb,
  .d-tbOnly {
    display: block !important;
  }

}

@include media(sp) {

  .d-pc,
  .d-tbOnly {
    display: none !important;
  }

  .d-sp {
    display: block !important;
  }
}
