/* pagetop
------------------------------------------------ */
.pagetop {
  position: fixed;
  right: 2%;
  bottom: 8%;
  z-index: 1;
  display: none;

  @include media(tb) {
    right: 20px;
  }

  @include media(sp) {
    right: 15px;
  }

  &>a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    background-color: $color-main;
    border-radius: 50%;

    @include media(pc-s) {
      width: res(50px);
    }

    @include media(sp) {
      width: 40px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &>svg {
      width: 18px;
      height: 22px;
      transition: transform .2s;

      @include media(pc-s) {
        width: res(18px);
        height: res(22px);
      }

      @include media(sp) {
        width: 10.8px;
        height: 13.2px;
      }
    }

    &:hover {

      &>svg {
        transform: translateY(-5px);

        @include media(tb) {
          transform: translateY(0);
        }
      }
    }
  }
}
