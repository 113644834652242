/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;


  &__inner {
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    margin: 0 auto;
    background-color: $color-main;

    @include media(pc-s) {
      height: res(80px);
    }

    @include media(sp) {
      height: 62px;
      padding: 14px 20px;
    }
  }

  .header-logo {
    padding-left: 28px;
    z-index: 100;


    @include media(pc-s) {
      padding-left: res(28px);
    }

    @include media(sp) {
      padding: 0;
    }

    img {
      width: 152px;
    }
  }



  // sublist
  .gnav .sublist,
  .reservation .sublist {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    display: none; // ドロップダウン初期値

    &__item {
      width: 208px;
      background-color: $color-wh;
      border-bottom: 1px solid $color-wh;

      @include media(pc-s) {
        width: res(208px);
      }

      &:last-child {
        border-bottom: none;
      }

      &>a {
        display: block;
        padding: 14px 13px;
        font-size: 1.4rem;
        letter-spacing: track(30);
        color: $color-gray;

        @include media(pc-s) {
          padding: res(14px) res(13px);
          font-size: res(1.4rem);
        }

        &:hover {
          color: $color-pink;

          @include media(tb) {
            color: $color-gray !important;
          }
        }
      }
    }
  }

  // gnav
  .gnav {
    @include media(sp) {
      display: none;
    }

    &__list {
      display: flex;
      align-items: center;
    }

    &__item {
      position: relative;
      display: block;
      margin-right: 14px;
      font-size: 1.5rem;
      letter-spacing: track(60);
      font-family: $font-mon;
      font-weight: bold;

      @include media(pc-s) {
        margin-right: res(14px);
        font-size: res(1.5rem);
      }

      &:last-child {
        margin-right: 32px;

        @include media(pc-s) {
          margin-right: res(32px);
        }
      }


      a {
        display: block;
        padding: 10px;
        &::before {
          position: absolute;
          content: "";
          width: 0;
          height: 1px;
          background-color: $color-wh;
          bottom: 0;
          left: 0;
        }
        &:hover {
          color: $color-wh;
          position: relative;
          &::before {
            width: 100%;
            transition: all .2s;
          }

          @include media(tb) {
            color: $color-bk;
          }
        }
      }
    }
  }
}



/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px) {

}
