.title {
  display: inline-block;
  color: $color-main;
  font-size: 3.6rem;
  font-weight: bold;
  text-align: center;
  font-family: $font-mon;
  @include media(pc-s) {
    font-size: res(3.2rem);
  }
  @include media(sp) {
    display: block;
    font-size: 3rem;
  }

  .ja {
    display: block;
    margin-top: 6px;
    font-size: 1.4rem;
    font-family: $font;
    @include media(pc-s) {
      margin-top: res(6px);
      font-size: res(1.2rem);
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }
}

.lower-main {
  .title {
    display: block;
    font-size: 3.2rem;
    text-align: center;
    @include media(pc-s) {
      font-size: res(3.2rem);
    }
  }
  @include media(sp) {
    display: block;
    font-size: 3rem;
  }
}
