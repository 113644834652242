.header__btn {
  display: none;

  @include media(tb) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
  }

  @include media(sp) {
    width: 50px;
    height: 50px;
  }
}

// drawer-btngnav-sp

.gnav-sp {
  display: none;
  @include media(sp) {
    display: block;
  }
}
.drawer-btn {
  position: relative;
  width: 30px;
  height: 22px;
  z-index: 101;
  cursor: pointer;

  &>span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: $color-bk;
    transform-origin: 50%;
    transition: .4s;

    &:nth-of-type(1) {
      transform: rotate(0) translateY(-12px);

      @include media(sp) {
        transform: rotate(0) translateY(-11px);
      }
    }

    &:nth-of-type(3) {
      transform: rotate(0) translateY(12px);

      @include media(sp) {
        transform: rotate(0) translateY(11px);
      }
    }
  }

  &.active {

    &>span {

      &:nth-of-type(1) {
        transform: translateY(0) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}


//　drawer-modal
.drawer-modal {
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-wh;
  transition: all .5s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    width: 100%;
    height: 100%;
    padding-top: 64px;

    @include media(sp) {
      padding-top: 60px;
    }
  }

  &__container {
    height: 500px;
  }

  &__list {
    width: 100%;
  }

  &__item {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $color-text;
    text-align: center;

    &.recruit {
      background-color: $color-main;
      font-weight: bold;
      a {
        color: $color-wh;
      }
    }
    &.contact {
      background-color: $color-bk;
      font-weight: bold;
      a {
        color: $color-wh;
      }
    }


    &>a,
    &>span {
      display: block;
      padding: 22px 32px;
      font-size: 1.6rem;
      letter-spacing: track(100);
      color: $color-text;
    }



  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px) {

  .drawer-modal {
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;


    &__container {
      height: 300px !important;
      margin-bottom: 50px;
    }
  }
}
